<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep2Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <h2 class="tw-mt-0">Verwarming</h2>
    <FormulateInput
      type="select"
      name="heating_source"
      label="Verwarmingsbron"
      :options="HEATING_SOURCE_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="natural_gas_meter"
      label="Aardgasmeter"
      :options="GAS_METER_OPTIONS"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="heating_boiler"
      label="Aansluiting verwarming"
      :options="HEATING_BOILER_OPTIONS"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="heating_type"
      label="Verwarmingstype"
      :options="HEATING_TYPE_OPTIONS"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.fuel_oil_tank"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="fuel_oil_tank"
      label="Stookolietank"
    />
    <template v-if="values.fuel_oil_tank == YES">
      <FormulateInput
        type="number"
        name="fuel_oil_tank_capacity"
        label="Inhoud"
        min="0"
        step=".01"
        :outer-class="['!tw-my-2']"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0']"
        :label-class="['tw-block md:tw-flex tw-items-center']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      >
        <div slot="suffix" class="input-unit !tw-my-0">
          l
        </div>
      </FormulateInput>
      <FormulateInput
        type="select"
        name="fuel_oil_tank_type"
        label="Stookolietanktype"
        :options="FUEL_OIL_TANK_TYPE_OPTIONS"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['!tw-mt-0']"
        :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
        :element-class="['tw-flex tw-items-center tw-col-span-2']"
      />
      <CustomRadioGroup
        v-model="values.fuel_oil_certificate"
        :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
        name="fuel_oil_certificate"
        label="Attest"
        class="tw-mb-4"
        :indent-label="true"
      />
    </template>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4" />
    <h2 class="tw-my-0">Energie</h2>
    <FormulateInput
      type="select"
      name="electricity_meter"
      label="Elektriciteitsmeter"
      :options="ELECTRICITY_METER_OPTIONS"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="electricity_meter_type"
      label="Type elektriciteitsmeter"
      :options="ELECTRICITY_METER_TYPE_OPTIONS"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.electric_installation_after_september_1981"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="electric_installation_after_september_1981"
      label="Elektrische installatie na 30/09/1981"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.conformity_test_certificate"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="conformity_test_certificate"
      label="Elektriciteitskeuring conform"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.has_solar_panels"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="has_solar_panels"
      label="Zonnepanelen"
      class="tw-mb-4"
    />
    <template v-if="values.has_solar_panels == YES">
      <FormulateInput
        type="number"
        name="solar_panels_purchase_year"
        label="Aankoopjaar"
        min="0"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['!tw-mt-0']"
        :label-class="['tw-block md:tw-flex tw-items-center tw-pl-8']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      >
      </FormulateInput>
      <FormulateInput
        type="number"
        name="solar_panels_installation_year"
        label="Installatiejaar"
        min="0"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['!tw-mt-0']"
        :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      />
      <FormulateInput
        type="number"
        name="solar_panels"
        label="kWh"
        min="0"
        step=".01"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0']"
        :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      >
        <div slot="suffix" class="input-unit !tw-my-0">
          kWh
        </div>
      </FormulateInput>
      <FormulateInput
        type="number"
        name="green_energy_certificates_value"
        label="Waarde groenestroomcertificaten"
        min="0"
        step=".01"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0']"
        :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      >
        <div slot="suffix" class="input-unit !tw-my-0">
          €
        </div>
      </FormulateInput>
    </template>
    <CustomRadioGroup
      v-model="values.charging_station"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="charging_station"
      label="Laadpaal"
    />
    <FormulateInput
      v-if="values.charging_station === 1"
      type="number"
      name="charging_point_amount"
      label="Aantal laadpunten"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <CustomRadioGroup
      v-model="values.charging_station_connection"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="charging_station_connection"
      label="Aansluiting voor laadpaal"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.battery"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="battery"
      label="Thuisbatterij"
      class="tw-mb-4"
    />
    <FormulateInput
      v-if="values.battery === 1"
      type="number"
      name="battery_capacity"
      label="Vermogen"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-my-0']"
      :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
      >
      <div slot="suffix" class="input-unit !tw-my-0">
        kWh
      </div>
    </FormulateInput>
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-gap-x-2 tw-justify-end">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import {
  ELECTRICITY_METER_OPTIONS,
  ELECTRICITY_METER_TYPE_OPTIONS,
  FUEL_OIL_TANK_TYPE_OPTIONS,
  GAS_METER_OPTIONS,
  HEATING_BOILER_OPTIONS,
  HEATING_SOURCE_OPTIONS,
  HEATING_TYPE_OPTIONS,
  YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS
} from '@/forms/selectOptions'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updatePropertyEnergy } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep2',
  components: {
    CustomRadioGroup
  },
  constants: {
    ELECTRICITY_METER_OPTIONS,
    ELECTRICITY_METER_TYPE_OPTIONS,
    FUEL_OIL_TANK_TYPE_OPTIONS,
    GAS_METER_OPTIONS,
    HEATING_BOILER_OPTIONS,
    HEATING_SOURCE_OPTIONS,
    HEATING_TYPE_OPTIONS,
    YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS,
    YES: 1
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues, has_solar_panels: this.initialValues.solar_panels ? 1 : 0 }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        const {
          has_solar_panels, // `has_solar_panels` not persisted in the database (https://dewaele.atlassian.net/wiki/spaces/DI/pages/2495840257/Wizard+Taxatie#Wizard-Step-2-en-3)
          ...payload
        } = data
        if (parseInt(payload.fuel_oil_tank) !== this.YES) {
          payload.fuel_oil_tank_capacity = null
          payload.fuel_oil_tank_type = 0 // 0 = not applicable
          payload.fuel_oil_certificate = 0 // 0 = unknown
        }
        if (parseInt(has_solar_panels) !== this.YES) {
          payload.solar_panels = null
          payload.solar_panels_purchase_year = null
          payload.solar_panels_installation_year = null
          payload.green_energy_certificates = 0 // 0 = unknown
          payload.green_energy_certificates_value = null
        }
        if (payload.green_energy_certificates_value) {
          payload.green_energy_certificates = 1 // Is a yes-no-unknown field that must not be visible in the form
        } else {
          payload.green_energy_certificates = 0 // 0 = unknown
        }
        if (parseInt(payload.charging_station) !== this.YES) {
          payload.charging_point_amount = null
        }
        if (parseInt(payload.battery) !== this.YES) {
          payload.battery_capacity = 0 // BE doesn't accept null, unfortunately
        }
        const energyResponse = await updatePropertyEnergy(this.propertyId, payload)
        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return energyResponse
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep2Form')
        throw error
      }
    }
  }
}
</script>
